const newsKey = 'hideNews'
export const isBrowser = () => typeof window !== 'undefined'
export const isMobile = () => {
  if (isBrowser()) {
    return window.innerWidth < 992
  }
}

export const getUser = () =>
  isBrowser() && window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null

const setUser = user => {
  if (isBrowser()) window.localStorage.setItem('user', JSON.stringify(user))
}

export const setName = name => {
  if (isBrowser()) window.localStorage.setItem('fullName', name)
}
export const getName = () =>
  isBrowser() && window.localStorage.getItem('fullName') ? window.localStorage.getItem('fullName') : ''

export const handleSignIn = user => {
  setUser(user)
  setName('')
}

export const getHideAlert = () =>
  isBrowser() && window.sessionStorage.getItem(newsKey) ? window.sessionStorage.getItem(newsKey) === 'true' : false

export const setHideAlertOnSession = () => {
  if (isBrowser()) window.sessionStorage.setItem(newsKey, 'true')
}
export const isLoggedIn = () => {
  const user = getUser()
  return !!user
}
