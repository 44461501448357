import 'bootstrap'
import './src/styles/main.scss'
import * as Sentry from '@sentry/react'
import { getUser, isLoggedIn } from './src/services/auth'

//Sentry Integration
const activeEnv = process.env.NODE_ENV || process.env.GATSBY_ACTIVE_ENV || 'development'

if (activeEnv !== 'development') {
  Sentry.init({ dsn: process.env.GATSBY_SENTRY_KEY, environment: activeEnv })
  if (isLoggedIn()) {
    const { email, fullName } = getUser()
    Sentry.setUser({ email, username: fullName })
  }
}

//Campaign monitor integration
const addScript = url => {
  const script = document.createElement('script')
  script.src = url
  script.async = true
  document.body.appendChild(script)
}

export const onClientEntry = () => {
  window.onload = () => {
    addScript('https://js.createsend1.com/javascript/copypastesubscribeformlogic.js')
  }
}

const script = document.createElement('script')

script.src = `${process.env.GATSBY_DIGITAL_ID_URL}/sdk/app.js`
script.async = true

document.body.appendChild(script)
